<template>
  <div class="userinfo" v-click-outside="closeInfo">
    <span class="el-dropdown-link" @click.prevent="openInfo">
      <imgwrapper v-bind:classes="'userimage img-wrapper-small'" v-bind:item="user" ></imgwrapper>
      {{ user.first_name }} {{ user.last_name }}<br />
      <span class="text-grey" style="font-size:10px;">{{ currentUser.label }}, {{ currentLocation.label }}</span>
    </span>

    <q-menu>
      <q-list style="min-width: 100px">
        <q-item v-if="!isSales && !isSeo && !isMarketing" clickable v-close-popup @click.prevent="scannerOpen = true" >
          <q-item-section>{{ $t('app.dashboard.scancustomer' ) }}</q-item-section>
        </q-item>
        <q-item v-if="!isSales && !isSeo && !isMarketing" clickable v-close-popup @click.prevent="$router.push('/settings/user')" >
          <q-item-section>{{ $t('app.navigation.personaldata' ) }}</q-item-section>
        </q-item>
        <q-item v-if="!isSales && !isSeo && !isMarketing && hasRight( 'settings' )" clickable v-close-popup @click.prevent="$router.push('/settings/settings')" >
          <q-item-section>{{ $t('app.navigation.einstellungen' ) }}</q-item-section>
        </q-item>
        <q-separator v-if="!isSales && !isSeo && !isMarketing" />

        <q-item clickable v-if="( user.locations == 0 || locations.length ) && !isSales && !isSeo && !isMarketing">
          <q-item-section>{{ $t('app.navigation.location' ) }}: {{ currentLocation.label }}</q-item-section>
          <q-item-section side>
            <q-icon name="keyboard_arrow_right" />
          </q-item-section>
          <q-menu>
            <q-list style="min-width: 200px" anchor="top end">
                <q-item clickable v-close-popup @click.prevent="changeLocation( 0, $t('app.navigation.all' ) )">
                  <q-item-section> {{ $t('app.navigation.all' ) }}</q-item-section>
                </q-item>
                <q-item v-for="item in locations" clickable v-close-popup v-bind:key="item.id" @click.prevent="changeLocation( item.id, item.name )">
                  <q-item-section> <span><q-icon name="done" color="teal" v-if="item.id == currentLocation.id" /> {{ item.name }}</span></q-item-section>
                </q-item>
            </q-list>
          </q-menu>
        </q-item>

        <q-item clickable v-if="user.type == 'admin' || user.type == 'director'">
          <q-item-section>{{ $t('app.navigation.person' ) }}: {{ currentUser.label }}</q-item-section>
          <q-item-section side>
            <q-icon name="keyboard_arrow_right" />
          </q-item-section>
          <q-menu>
            <q-list style="min-width: 200px" anchor="top end">
                <q-item clickable v-close-popup @click.prevent="changeUser( 0, $t('app.navigation.you' ) )">
                  <q-item-section>{{ $t('app.navigation.you' ) }}</q-item-section>
                </q-item>
                <q-item v-for="item in users" v-if="item.type != 'admin' && item.type != 'director' && item.type != 'datacheck' && item.type != 'api'" clickable v-close-popup v-bind:key="item.id" @click.prevent="changeUser( item.id, item.first_name + ' ' + item.last_name )">
                  <q-item-section>{{ item.title }} {{ item.first_name }} {{ item.last_name }}</q-item-section>
                </q-item>
            </q-list>
          </q-menu>
        </q-item>

        <q-separator v-if="!isSales && !isSeo && !isMarketing" />
        <q-item clickable v-close-popup @click.prevent="logout">
          <q-item-section>{{ $t('app.navigation.logout' ) }}</q-item-section>
        </q-item>
      </q-list>
    </q-menu>



    <modal @close="closeModal" v-if="!isSales && !isSeo && !isMarketing && scannerOpen">
      <h3 class="title" slot="header" v-if="!customerScanned">{{ $t( 'app.dashboard.scancustomer' ) }}</h3>
      <h3 class="title" slot="header" v-if="customerScanned">&nbsp;{{ customerScanned.name }}</h3>
      <div slot="body">
        <div class="clear text-block">
            <div class="form-field eanscanner" style="width: 300px; height: 210px;" v-if="!customerScanned">
                <qrcode-stream @decode="scanned" @init="onInit" />
            </div>
            <div class="form-field" v-if="customerScanned">
                <img class="img-responsive" :src="'https://chart.googleapis.com/chart?chs=350x350&cht=qr&chld=L|0&chl=' + customerScanned.link" style="width:100%;" />
            </div>
        </div>
        <q-btn @click.prevent="closeModal" v-if="!customerScanned" color="primary" :label="$t( 'app.dashboard.abort' )" size="md" />
      </div>
    </modal>


  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { QrcodeStream } from 'vue-qrcode-reader'
import { hasRight } from '@/services/hasRight';
const user = JSON.parse(localStorage.getItem("user"));

export default {
  name: "Userinfo",
  components: { QrcodeStream },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      isOpen: false,
      user,
      users: [],
      locations: [],
      scannerOpen: false,
      customerScanned: false,
      hasRight,
      readers: [ 'ean_reader' ]
    };
  },
  created: function(){
    if ( !this.isSales && !this.isSeo && !this.isMarketing ) {
        this.getLocations();
        this.getUsers();
        let uLocations = [];
        if ( user.locations ) {
            uLocations = user.locations.split( ',' );
        }
        if ( uLocations.indexOf( '0' ) < 0 && uLocations.indexOf( this.currentLocation.id + '' ) < 0 ) {
            this.changeLocation( uLocations[ 0 ], '' );
        }
        if ( user.type != 'admin' && user.type != 'director' && this.currentUser.id != user.id ) {
            this.changeUser( user.id, '' );
        }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.go("/login");
      });
    },
    scanned( result ) {
        if ( this.scannerOpen ) {
            const ean = result.split( '|' );
            if ( ean.length > 1 ) {
                this.$http
                  .get(`${window._serverAPI}/free/run?id=${ean[ 1 ]}&authkey=${user.api_token}`) // TODO: locationfilter
                  .then((response) => {
                      if ( response.data.link ) {
                          this.customerScanned = {
                              link: encodeURIComponent( response.data.link ),
                              name: response.data.customer
                          }
                          this.$q.notify({ type: "positive", position: "top", message: this.$t( 'app.dashboard.customerinwaitingroom' ), timeout: 2000, });
                      } else {
                          this.$q.notify({ type: "positive", position: "top", message: this.$t( 'app.dashboard.customerwaitingroomfailure' ), timeout: 2000, });
                      }
                  });
            }
            console.log( ean );
            // this.closeModal();
        }
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        console.log( error );
      }
    },
    closeModal() {
      this.scannerOpen = false;
      this.customerScanned = false;
    },
    openInfo() {
      this.isOpen = true;
    },
    closeInfo() {
      this.isOpen = false;
    },
    changeLocation: function( id, label ) {
      this.$store.commit( 'toggleLocation', { id, label } );
    },
    changeUser: function( id, label ) {
      this.$store.commit( 'toggleUser', { id, label } );
    },
    getUsers: function(){
        this.$http
          .get(`${window._serverAPI}/users?key=${user.api_token}`) // TODO: locationfilter
          .then((response) => {
            this.users = response.data;
          })
          .catch((err) => console.log(err));
    },
    getLocations: function(){
        this.$http
          .get(`${window._serverAPI}/locations?key=${user.api_token}`)
          .then((response) => {
            this.locations = response.data;
          })
          .catch((err) => console.log(err));
    }
  },
  computed: {
    currentLocation() {
        return this.$store.state.location;
    },
    isSales() {
      return this.$store.getters.isSales;
    },
    isSeo() {
      return this.$store.getters.isSeo;
    },
    isMarketing() {
      return this.$store.getters.isMarketing;
    },
    currentUser() {
        return this.$store.state.user;
    }
  },
  directives: {
    ClickOutside,
  },
};
</script>
