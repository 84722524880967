<template>
  <div class="product-item flex flex-middle" v-if="item">
    <imgwrapper v-bind:classes="'img-wrapper-small'" v-bind:item="item" ></imgwrapper>
    <div>
      <strong><q-icon name="star_rate" class="text-warning" v-if="item.favorite" /> {{ item.name }} </strong>
      <span v-if="item.categoriename" class="block">
        {{item.categoriename}}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  props: ["item"],
  data() {
    return {};
  },
  mounted() {},
};
</script>
