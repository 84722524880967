<template>
  <div class="user-item flex flex-middle">
    <span :class="{ 'task-dot': true, 'task-state-success': isOnline( item ) }"></span>
    <imgwrapper v-bind:classes="'img-wrapper-small'" v-bind:item="item"></imgwrapper>
    <div>
      <strong>{{ item.first_name }} {{ item.last_name }}</strong>
      <span class="block" v-if="item.type == 'doctor'">{{ $t( 'app.other.doctor' ) }}</span>
    </div>
  </div>
</template>

<script>
import { format, parseISO, differenceInYears, fromUnixTime, differenceInMinutes } from 'date-fns';

export default {
  name: 'user',
  props: ['item'],
  data() {
    return {};
  },
  methods: {
    isOnline( item ) {
        if ( !item.api_token_time ) { return false; }
        return ( differenceInMinutes( new Date(), fromUnixTime( item.api_token_time ) ) < 15 ? true : false );
    }
  },
  filters: {
    formatAge(val) {
      if (val) {
        const age = differenceInYears(new Date(), parseISO(val));
        return Number.isInteger(age) ? age : null;
      }
    },
  },
};
</script>
