import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store.js';
import { hasRight } from '@/services/hasRight';
import { privateAuth } from '@/services/privateAuth';

Vue.use(VueRouter);

const routes = [{
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/public/content',
    name: 'publiccontent',
    component: () => import('../views/private/content.vue')
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import('../views/Statistics.vue'),
    meta: {
      requiresRoleAuth: 'statistics',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/auth/Login.vue'),
    meta: {
      guest: true,
      requiresAuth: false,
    },
  },
  {
    path: '/customerauth',
    name: 'customerauth',
    component: () => import('../components/auth/customerauth.vue'),
    meta: {
      guest: true,
      requiresAuth: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../components/auth/Register.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('../views/calendar/index.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/customer',
    redirect: '/customer/all',
  },
  {
    path: '/customer/all',
    name: 'customerall',
    component: () => import('../views/customer/All.vue'),
    meta: {
      requiresRoleAuth: 'customer',
      requiresAuth: true,
    },
  },
  {
    path: '/customer/:id',
    name: 'customerDetail',
    component: () => import('../views/customer/detail/index.vue'),
    meta: {
      requiresRoleAuth: 'customer',
      requiresAuth: true,
    },
  },
  {
    path: '/vapp/:token/:minutes',
    name: 'virtualConsultationAppointment',
    component: () => import('../components/appointments/vapp.vue'),
    meta: {
      requiresRoleAuth: 'customer',
      requiresAuth: true,
    },
  },
  {
    path: '/ocviewer',
    name: 'virtualConsultationAppointmentGenerator',
    component: () => import('../components/appointments/calendar.vue'),
    /*
    meta: {
      requiresRoleAuth: 'customer',
      requiresAuth: true,
    },
    */
  },
  {
    path: '/customer/:id/:tab',
    name: 'customerDetailTab',
    component: () => import('../views/customer/detail/index.vue'),
    meta: {
      requiresRoleAuth: 'customer',
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/settings/index.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/attachments',
    name: 'SettingsAttachments',
    component: () => import('../views/settings/attachments.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/communication',
    name: 'SettingsCommunications',
    component: () => import('../views/settings/Communication.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/sales',
    name: 'SettingsSales',
    component: () => import('../views/settings/Sales.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/bonus',
    name: 'BonusSales',
    component: () => import('../views/settings/Bonus.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/user',
    name: 'UserSettings',
    component: () => import('../views/settings/UserSettings.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/onlinecalendar',
    name: 'OnlineCalendar',
    component: () => import('../views/settings/OnlineCalendar.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/company',
    name: 'CompanySettings',
    component: () => import('../views/settings/CompanySettings.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/companies',
    name: 'CompaniesSettings',
    component: () => import('../views/settings/Companies.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/locations',
    name: 'LocationsSettings',
    component: () => import('../views/settings/Locations.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/modules',
    name: 'Modules',
    component: () => import('../views/settings/Modules.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/payment',
    name: 'Payment',
    component: () => import('../views/settings/Payment.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/settings',
    name: 'AppSettings',
    component: () => import('../views/settings/Settings.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/messages',
    name: 'Messages',
    component: () => import('../views/settings/Messages.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/sms',
    name: 'Sms',
    component: () => import('../views/settings/Sms.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/callnotes',
    name: 'callnotes',
    component: () => import('../views/settings/callnotes.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/invoicenotes',
    name: 'invoicenotes',
    component: () => import('../views/settings/invoicenotes.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/onlinequestionwords',
    name: 'onlinequestionwords',
    component: () => import('../views/settings/onlinequestionwords.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/pixeltrackings',
    name: 'PixelTrackings',
    component: () => import('../views/settings/PixelTrackings.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/reports',
    name: 'Reports',
    component: () => import('../views/settings/Reports.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/patientdatawelcome',
    name: 'patientdatawelcome',
    component: () => import('../views/settings/patientdatawelcome.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/documents',
    name: 'Documents',
    component: () => import('../views/settings/Documents.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/informings',
    name: 'Informings',
    component: () => import('../views/settings/informings.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/calendar',
    name: 'CalendarSettings ',
    component: () => import('../views/settings/Calendar.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/contracts',
    name: 'ContractsSettings ',
    component: () => import('../views/settings/Contracts.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/dynamicoffer',
    name: 'dynamicofferSettings',
    component: () => import('../views/settings/Dynamicoffer.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/fixedoffer',
    name: 'fixedofferSettings',
    component: () => import('../views/settings/Fixedoffer.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/goaereasons',
    name: 'goaereasonsSettings',
    component: () => import('../views/settings/goaereasons.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/ratinglinks',
    name: 'ratinglinksSettings',
    component: () => import('../views/settings/Ratinglinks.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/conditions',
    name: 'ConditionsSettings ',
    component: () => import('../views/settings/Conditions.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/recall',
    name: 'RecallSettings ',
    component: () => import('../views/settings/Recall.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/privacy',
    name: 'PrivacySettings ',
    component: () => import('../views/settings/Privacy.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/imap',
    name: 'ImapSettings ',
    component: () => import('../views/settings/Imap.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/privacy2',
    name: 'PrivacySettings2',
    component: () => import('../views/settings/privacy2.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/conditions2',
    name: 'Conditions2Settings ',
    component: () => import('../views/settings/conditions2.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/imprint2',
    name: 'Imprint2Settings ',
    component: () => import('../views/settings/imprint2.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/additionalconditions',
    name: 'AdditionalConditions',
    component: () => import('../views/settings/additionalconditions.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/paymentadvices',
    name: 'PaymentadvicesSettings ',
    component: () => import('../views/settings/Paymentadvices.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/algo',
    name: 'AlgoSettings ',
    component: () => import('../views/settings/Algo.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/settings/salespresets',
    name: 'salespresetsSettings ',
    component: () => import('../views/settings/salespresets.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'settings',
    },
  },
  {
    path: '/postal',
    name: 'incomePostal',
    component: () => import('../views/postal/postal.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'postal',
    },
  },
  {
    path: '/finance',
    redirect: '/finance/open_invoices',
  },
  {
    path: '/finance/open_invoices',
    name: 'FinanceInvoices1',
    component: () => import('../views/finance/outgoing_invoices.vue'),
    props: { isArchive: false, isStorno: false, isOpen: true, isClosed: false },
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'invoices',
    },
  },
  {
    path: '/finance/closed_invoices',
    name: 'FinanceInvoices2',
    component: () => import('../views/finance/outgoing_invoices.vue'),
    props: { isArchive: false, isStorno: false, isOpen: false, isClosed: true },
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'invoices',
    },
  },
  {
    path: '/finance/invoices',
    name: 'FinanceInvoices3',
    component: () => import('../views/finance/outgoing_invoices.vue'),
    props: { isArchive: false, isStorno: false, isOpen: false, isClosed: false },
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'invoices',
    },
  },
  {
    path: '/finance/archive',
    name: 'FinanceInvoices4',
    component: () => import('../views/finance/outgoing_invoices.vue'),
    props: { isArchive: true, isStorno: false, isOpen: false, isClosed: false },
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'invoices',
    },
  },
  {
    path: '/finance/storno',
    name: 'FinanceInvoices5',
    component: () => import('../views/finance/outgoing_invoices.vue'),
    props: { isArchive: false, isStorno: true, isOpen: false, isClosed: false },
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'invoices',
    },
  },
  {
    path: '/finance/expenses',
    name: 'FinanceExpenses',
    component: () => import('../views/finance/incoming_invoices.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'invoices',
    },
  },
  {
    path: '/finance/reminders',
    name: 'FinanceReminders',
    component: () => import('../views/finance/reminders.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'invoices',
    },
  },
  {
      path: '/finance/prebilance',
      name: 'prebilance',
      component: () => import('../views/finance/prebilance.vue'),
      props: { isArchive: false, isStorno: false, isOpen: false, isClosed: false },
      meta: {
        requiresAuth: true,
        requiresRoleAuth: 'invoices',
      },
  },
  {
    path: '/hr',
    redirect: '/hr/staff',
  },
  {
    path: '/hr/staff',
    name: 'Staff',
    component: () => import('../views/hr/Staff.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'users',
    },
  },
  {
    path: '/products',
    redirect: '/products/all',
  },
  {
    path: '/products/all',
    name: 'Archive',
    component: () => import('../views/product/Archive.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'products',
    },
  },
  {
    path: '/products/categories',
    name: 'CategorieArchive',
    component: () => import('../views/product/Categories.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'products',
    },
  },
  {
    path: '/goods',
    name: 'Goods',
    component: () => import('../views/goods/goods.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'goods/read',
    },
  },
  {
    path: '/newsletter',
    name: 'Newsletter',
    component: () => import('../views/newsletter.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'newsletter',
    },
  },
  {
    path: '/coupons',
    name: 'Coupons',
    component: () => import('../views/coupons/index.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'coupons',
    },
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import('../views/task/task.vue'),
    meta: {
      requiresAuth: true,
      requiresRoleAuth: 'tasks',
    },
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: () => import('../views/marketing/index.vue'),
    meta: {
      requiresAuth: true,
      marketing: true,
    },
  },
  {
    path: '/seo',
    name: 'seo',
    component: () => import('../views/seo/index.vue'),
    meta: {
      requiresAuth: true,
      seo: true,
    },
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import('../views/sales/index.vue'),
    meta: {
      requiresAuth: true,
      sales: true,
    },
  },
  {
    path: '/sales/login',
    name: 'saleslogin',
    component: () => import('../views/sales/login.vue'),
    meta: {
      guest: true,
      requiresAuth: false,
    },
  },
  {
    path: '/datacheck',
    name: 'dataCheck',
    component: () => import('../views/datacheck/index.vue'),
    meta: {
      requiresAuth: true,
      dataCheck: true,
    },
  },
  {
    path: '/datacheck/document/:id',
    name: 'dataCheckDocument',
    component: () => import('../views/datacheck/document.vue'),
    meta: {
      requiresAuth: true,
      dataCheck: true,
    },
  },
  {
    path: '/datacheck/beforecontrol/:id',
    name: 'dataCheckBeforecontrol',
    component: () => import('../views/datacheck/beforecontrol.vue'),
    meta: {
      requiresAuth: true,
      dataCheck: true,
    },
  },
  {
    path: '/datacheck/rateoperation/:id',
    name: 'dataCheckRateoperation',
    component: () => import('../views/datacheck/rateoperation.vue'),
    meta: {
      requiresAuth: true,
      dataCheck: true,
    },
  },
  {
    path: '/datacheck/:id/:step',
    name: 'dataCheckCustomer',
    component: () => import('../views/datacheck/customer.vue'),
    meta: {
      requiresAuth: true,
      dataCheck: true,
    },
  },
  {
    path: '/datacheck/:id/',
    redirect: '/datacheck/:id/1',
  },
  {
    path: '/rating/:company',
    name: 'ratingIndex',
    component: () => import('../views/rating.vue'),
  },
  {
    path: '/private',
    name: 'privateIndex',
    component: () => import('../views/private/index.vue'),
  },
  {
    path: '/private/pay',
    name: 'privatePay',
    component: () => import('../views/private/pay.vue'),
  },
  {
    path: '/private/nlout',
    name: 'privateNLOut',
    component: () => import('../views/private/nlout.vue'),
  },
  {
    path: '/private/done',
    name: 'privateDone',
    component: () => import('../views/private/done.vue'),
  },
  {
    path: '/private/document/:id',
    name: 'privateCheckDocument',
    component: () => import('../views/private/document.vue'),
    meta: {
      requiresPrivateAuth: true
    },
  },
  {
    path: '/private/appointment/:id',
    name: 'privateAppointment',
    component: () => import('../views/private/appointment.vue'),
    meta: {
      requiresPrivateAuth: true
    },
  },
  {
    path: '/private/invoice/:id',
    name: 'privateInvoice',
    component: () => import('../views/private/invoice.vue'),
    meta: {
      requiresPrivateAuth: true
    },
  },
  {
    path: '/private/proposal/:id',
    name: 'privateProposal',
    component: () => import('../views/private/proposal.vue'),
    meta: {
      requiresPrivateAuth: true
    },
  },
  {
    path: '/private/treatment/:id',
    name: 'privateTreatment',
    component: () => import('../views/private/treatment.vue'),
    meta: {
      requiresPrivateAuth: true
    },
  },
  {
    path: '/private/beforecontrol',
    name: 'privateBeforecontrol',
    component: () => import('../views/private/beforecontrol.vue'),
    meta: {
      requiresPrivateAuth: true
    },
  },
  {
    path: '/private/rateoperation',
    name: 'privateRateoperation',
    component: () => import('../views/private/rateoperation.vue'),
    meta: {
      requiresPrivateAuth: true
    },
  },
  {
    path: '/private/customer/:step',
    name: 'privateCheckCustomer',
    component: () => import('../views/private/customer.vue'),
    meta: {
      requiresPrivateAuth: true
    },
  },
  {
    path: '/private/dynamicoffer',
    name: 'privatedynamicoffer',
    component: () => import('../views/private/dynamicoffer.vue'),
  },
  {
    path: '/private/vapp/:key/:token',
    name: 'virtualConsultationAppointmentCustomer',
    component: () => import('../components/appointments/customervapp.vue'),
  },
  {
    path: '/private/verifyemail',
    name: 'privateVerifyemail',
    component: () => import('../views/private/verifyemail.vue'),
  },
  {
    path: '/private/verifyphone',
    name: 'privateVerifyphone',
    component: () => import('../views/private/verifyphone.vue'),
  },
  {
    path: '/private/customer/',
    redirect: '/private/customer/1',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresPrivateAuth)) {
      let path = false;
      if ( to.name == 'privateAppointment' ) {
          path = { type: 'appointment', param: to.params.id };
      }
      if ( to.name == 'privateInvoice' ) {
          path = { type: 'invoice', param: to.params.id };
      }
      if ( to.name == 'privateTreatment' ) {
          path = { type: 'treatment', param: to.params.id };
      }
      privateAuth.isLoggedIn( function(){ next(); }, function(){ next( '/private' ); }, path )
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      if ( !hasRight( 'apitest' ) ) {
          next( '/login' );
      } else {
          console.log( store.getters.isMarketing );
          if (store.getters.isDataCheck) {
            if (to.matched.some((record) => record.meta.dataCheck)) {
              next();
            } else {
              next('/datacheck');
            }
          } else if (store.getters.isSales) {
            if (to.matched.some((record) => record.meta.sales)) {
              next();
            } else {
              next('/sales');
            }
          } else if (store.getters.isSeo) {
            if (to.matched.some((record) => record.meta.seo)) {
              next();
            } else {
              next('/seo');
            }
          } else if (store.getters.isMarketing) {
            if (to.matched.some((record) => record.meta.marketing)) {
              next();
            } else {
              next('/marketing');
            }
          } else {
              if ( to.matched.some((record) => record.meta.requiresRoleAuth ) ) {
                 if ( to.matched.some((record) => hasRight( record.meta.requiresRoleAuth ) ) ) {
                    next();
                 } else {
                    next( '/dashboard' );
                 }
              } else {
                  next();
              }
          }
      }
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;
