<template>
  <div v-if="hasRight( 'customer' )" v-click-outside="clearSearch" style="position: relative;">

    <q-input :class="'input-search'" v-model="searchQuery" @input="isTyping = true" type="search" :label="$t('app.other.searchpersons' )">
      <template v-slot:prepend>
        <q-icon size="20" :name="$icons.ionSearchOutline" />
      </template>
    </q-input>

    <div class="searchresults box" v-show="searchResult">
      <ul class="box-content" v-if="( searchResult && searchResult.length > 0 ) || ( presearch && presearch.length > 0 )">
        <li v-if="presearch && presearch.length > 0">{{ $t('app.other.lastsearches' ) }}</li>
        <li v-for="item in presearch" class="list-item" v-bind:key="'p'+item.id">
          <a @click.prevent="openCustomer( item )" class="flex flex-middle">
            <customer v-bind:item="item"></customer>
          </a>
        </li>
        <li v-if="searchResult && searchResult.length">{{ $t('app.other.searchresults' ) }}</li>
        <li v-for="item in searchResult" class="list-item" v-bind:key="item.id">
          <a @click.prevent="openCustomer( item )" class="flex flex-middle">
            <customer v-bind:item="item"></customer>
          </a>
        </li>
      </ul>
      <ul v-else class="box-content">
        <li>{{ $t('app.other.nosearchresults' ) }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import ClickOutside from "vue-click-outside";
import { format, differenceInYears, toDate, parseISO } from "date-fns";
import { hasRight } from '@/services/hasRight';

const user = JSON.parse(localStorage.getItem("user"));

export default {
  name: "Search",
  data() {
    return {
      searchQuery: "",
      searchResult: null,
      presearch: null,
      isTyping: false,
      isLoading: false,
      hasRight
    };
  },
  methods: {
    openCustomer( item ) {
        // this.$router.push({ path: `/customer/${item.id}` });
        if ( item.blocked && item.blocked != user.id ) {
          return false;
        }

        const data = {
          last_searched: format( new Date(), "yyyy-MM-dd HH:mm:ss"),
          company: user.company
          // id: item.id
        };
        this.$http({
          url: `${window._serverAPI}/customers/${item.id}?key=${user.api_token}&_method=PUT`,
          data,
          method: "POST",
        }).then((resp) => {
            window.location.href = `/customer/${item.id}`;
        });

    },
    searchUser(searchQuery) {
      this.isLoading = true;
      if (searchQuery.length > 2) {
        this.$http
          .get(
            `${window._serverAPI}/customers/search?header=1&key=${user.api_token}&q=${searchQuery}&location=${this.currentLocation.id}&assigned_user=${this.currentUser.id}`
          )
          .then((response) => {
            this.isLoading = false;
            console.log( response.data );
            this.searchResult = response.data.searchResult;
            this.presearch = response.data.presearch;

          });
      } else {
        this.searchResult = null;
      }
    },
    clearSearch() {
      this.searchResult = null;
      this.searchQuery = "";
    },
  },
  computed: {
    currentLocation() {
        return this.$store.state.location;
    },
    currentUser() {
        return this.$store.state.user;
    }
  },
  watch: {
    searchQuery: debounce(function () {
      this.isTyping = false;
    }, 550),
    isTyping(value) {
      if (!value) {
        this.searchUser(this.searchQuery);
      }
    },
  },
  filters: {
    formatAge(val) {
      if (val) {
        return differenceInYears(new Date(), parseISO(val));
      }
    },
    formatDate(val) {
      return format(parseISO(val), "d.MM.yyyy");
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
