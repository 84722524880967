<template>
  <nav>
    <ul>
      <li class="dashboard">
        <router-link to="/dashboard">
          <q-icon name="dashboard" />
          {{ $t("app.navigation.dashboard") }}
        </router-link>
      </li>
      <li class="statistics" v-if="hasRight( 'statistics' )">
        <router-link to="/statistics">
          <q-icon name="insights" />
          {{ $t("app.navigation.statistik") }}
        </router-link>
      </li>
    </ul>
    <span>{{ $t("app.navigation.module") }}</span>
    <ul>
      <li index="akte" class="akte" v-if="hasRight( 'customer' )">
        <router-link to="/customer">
          <q-icon name="assignment" />
          {{ $t("app.navigation.akte") }}
        </router-link>
      </li>
      <li class="oc">
        <router-link to="/calendar" v-if="hasRight( 'calendar' )">
          <q-icon name="calendar_today" />
          {{ $t("app.navigation.kalendar") }}
        </router-link>
      </li>
      <li class="todo" v-if="isHRActive && hasRight( 'tasks' )">
        <router-link to="/tasks">
          <q-icon name="check_circle_outline" />
          {{ $t("app.navigation.aufgaben") }}
        </router-link>
      </li>
      <li class="finance" v-if="hasRight('invoices') && isFinanceActive">
        <router-link to="/finance">
          <q-icon name="account_balance" />
          {{ $t("app.navigation.finanzen") }}
        </router-link>
      </li>
      <li class="finance" v-if="hasRight('coupons') && isFinanceActive && isBeta">
        <router-link to="/coupons">
          <q-icon name="shopping_basket" />
          {{ $t("app.navigation.coupons") }}
        </router-link>
      </li>

      <li class="postal" v-if="isFinanceActive && hasRight('postal')">
        <router-link to="/postal">
          <q-icon name="description" />
          {{ $t("app.navigation.post") }}
        </router-link>
      </li>

      <li class="hr m-hide" v-if="hasRight('users')">
        <router-link to="/hr">
          <q-icon name="account_circle" />
          {{ $t("app.navigation.personal") }}
        </router-link>
      </li>

      <li class="hr m-hide" v-if="isMActive && hasRight('newsletter') && isBeta">
        <router-link to="/newsletter">
          <q-icon name="record_voice_over" />
          {{ $t("app.navigation.newsletter") }}
        </router-link>
      </li>

      <!--
      <li class="vc">
        <router-link to="/vc">Virtuelle Beratung</router-link>
      </li>
      -->
    </ul>

    <span v-if="hasRight('products') || hasRight('settings') || hasRight('goods')">{{ $t("app.navigation.einstellungen") }}</span>
    <ul v-if="hasRight('products') || hasRight('settings') || hasRight('goods')">
      <li class="settings m-hide" v-if="hasRight('products')">
        <router-link to="/products">
          <q-icon name="shopping_bag" />
          {{ $t("app.navigation.behandlungen") }}
        </router-link>
      </li>
      <li class="settings m-hide" v-if="hasRight('goods/read') && isIMActive">
        <router-link to="/goods">
          <q-icon name="local_shipping" />
          {{ $t("app.navigation.verbrauchsgueter") }}
        </router-link>
      </li>
      <li class="settings" v-if="hasRight('settings')">
        <router-link to="/settings">
          <q-icon name="settings" />
          {{ $t("app.navigation.einstellungen") }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { hasRight } from '@/services/hasRight';

export default {
  name: "Navigation",
  data() {
    return {
        hasRight
    };
  },
  computed: {
    isFinanceActive() {
        return this.$store.state.module6;
    },
    isHRActive() {
        return this.$store.state.module2;
    },
    isBeta() {
        return this.$store.state.beta;
    },
    isIMActive() {
        return this.$store.state.module5;
    },
    isMActive() {
        return this.$store.state.module7;
    }
  },

};
</script>
