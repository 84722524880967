<template>
  <div v-if="item && ( item.path || item.spath )" @click.prevent="click()" :class="'img-wrapper ' + classes + ' ' + ( item.verified_alt ? 'verified' : '' )" :style="{ backgroundImage: 'url(' + getBGImage( item ) + ')',}">
    <slot name="tooltip"></slot>
  </div>
  <div v-else :class="'img-wrapper flex-middle ' + classes + ' ' + colorClass">
    <slot name="tooltip"></slot>
    <div v-if="item && item.first_name && item.last_name" class="letters" @click.prevent="click()">
      {{ item.first_name | firstLetter }}{{ item.last_name | firstLetter }}
    </div>
    <div v-if="item && item.first_name && !item.last_name" class="letters" @click.prevent="click()">
      {{ item.first_name | firstLetter }}
    </div>
    <div v-if="item && item.name" class="letters" @click.prevent="click()">
      {{ item.name | firstLetter }}
    </div>
  </div>
</template>

<script>
// Used like so
const arr = [2, 11, 37, 42];

export default {
  name: "imgwrapper",
  props: ["item", "classes", "change", "forcespath"],
  filters: {
    firstLetter(val) {
      if (val) {
        return val.charAt(0);
      }
    },
  },
  created() {
    if ( this.item ) {
        if ( this.item.with_image ) {
            this.item.path = this.item.with_image.path;
        }
     }
  },
  methods: {
      click() {
          console.log( 'click' );
          this.$emit( 'click' );
      },
      getBGImage( item ) {
          console.log( this.forcespath );
          if ( !item.path && !item.spath ) { return ''; }
          if ( item.path && !this.forcespath ) {
              if ( item.path.indexOf( 'https://' ) >= 0 ) { return item.path; }
              else { return 'https://api.aestheticcloud.de/' + item.path; }
          }
          if ( item.spath ) {
              if ( item.spath.indexOf( 'https://' ) >= 0 ) { return item.spath; }
              else { return 'https://api.aestheticcloud.de/' + item.spath; }
          }
      }
  },
  computed: {
    // a computed getter
    colorClass() {
      const classValues = [
        "blue",
        "lightblue",
        "green",
        "lightgreen",
        "leafgreen",
        "darkgreen",
        "treegreen",
        "yellow",
        "orange",
        "brown",
        "red",
        "violett",
        "lightorange",
      ];
      const random = Math.floor(Math.random() * classValues.length);

      if ( !this.item ) { return "orange"; }
      if (this.item.gender) {
        return this.item.gender == "male" ? "blue" : "pink";
      }
      return "orange";

      // return classValues[random];
    },
  },
};
</script>
