import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem('user') || '',
    datacheck: (localStorage.getItem('type') == 'datacheck'),
    sales: (localStorage.getItem('type') == 'sales'),
    seo: (localStorage.getItem('type') == 'seo'),
    marketing: (localStorage.getItem('type') == 'marketing'),
    location: ( localStorage.getItem( 'currentLocation' ) ? JSON.parse( localStorage.getItem( 'currentLocation' ) ) : { id: 0, label: 'Alle' } ),
    user: ( localStorage.getItem( 'currentUser' ) ? JSON.parse( localStorage.getItem( 'currentUser' ) ) : { id: 0, label: 'Sie' } ),
    notifications: [],
    patients: [],
    beta: false,
    module1: false,
    module2: false,
    module3: false,
    module4: false,
    module5: false,
    module6: false,
    module7: false,
    module8: false,
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, token, user) {
      state.status = 'success';
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.token = '';
    },
    notify( state, notifications ) {
      state.notifications = notifications;
    },
    patients( state, patients ) {
      state.patients = patients;
      localStorage.setItem( 'patientStore', JSON.stringify( patients ) );
    },
    toggleLocation( state, location ) {
        state[ 'location' ] = location;
        localStorage.setItem( 'currentLocation', JSON.stringify( location ) );
    },
    toggleUser( state, user ) {
        state[ 'user' ] = user;
        localStorage.setItem( 'currentUser', JSON.stringify( user ) );
    },
    setBeta( state, beta ) {
        if ( beta === true || beta === 1 || beta === '1' ) {
            beta = true;
        } else {
            beta = false;
        }
        state[ 'beta' ] = beta;
    },
    initStore( state, activeModules ) {

        if ( activeModules ) {
            for ( let i = 0; i < activeModules.length; i++ ) {
                state[ 'module' + activeModules[ i ].id ] = activeModules[ i ].active;
            }
        }

        const exists = localStorage.getItem( 'patientStore' );
        state.patients = ( exists ? JSON.parse( exists ) : [] );
    }
  },
  actions: {
    login( { commit }, resp ) {
        commit('auth_request');
        const token = resp.data.data.api_token;
        const user = JSON.stringify(resp.data.data);
        localStorage.setItem('user', user);
        localStorage.setItem('token', token);
        localStorage.setItem('type', resp.data.data.type);

        axios.defaults.headers.common.Authorization = token;
        commit('auth_success', token, user);
        // resolve();
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        delete axios.defaults.headers.common.Authorization;
        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    notifications: (state) => state.notifications,
    patients: (state) => state.patients,
    isDataCheck: (state) => state.datacheck,
    isSales: (state) => state.sales,
    isSeo: (state) => state.seo,
    beta: (state) => state.beta,
    isMarketing: (state) => state.marketing,
    module1: (state) => state.module1,
    module2: (state) => state.module2,
    module3: (state) => state.module3,
    module4: (state) => state.module4,
    module5: (state) => state.module5,
    module6: (state) => state.module6,
    module7: (state) => state.module7,
    module8: (state) => state.module8,
    location: (state) => state.location,
    user: (state) => state.user
  },
});
