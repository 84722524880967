import {
  ionMailOutline,
  ionLockClosedOutline,
  ionArrowForward,
  ionSearchOutline,
  ionNotificationsOutline,
  ionCallOutline,
  ionCalendarOutline,
  ionHomeOutline,
  ionCheckmarkOutline,
  ionPencilOutline,
  ionInformationCircleOutline,
  ionEllipsisVertical,
  ionShareOutline,
  ionTrendingDownOutline,
  ionTrendingUpOutline,
  ionCloseOutline,
  ionAlertOutline,
  ionCreateOutline,
  ionTimeOutline,
  ionPersonCircleOutline,
  ionDocumentAttachOutline,
  ionImageOutline,
  ionChatboxOutline

} from "@quasar/extras/ionicons-v5";


export default function icons() {

  return {
    ionMailOutline,
    ionLockClosedOutline,
    ionArrowForward,
    ionSearchOutline,
    ionNotificationsOutline,
    ionCallOutline,
    ionCalendarOutline,
    ionHomeOutline,
    ionCheckmarkOutline,
    ionPencilOutline,
    ionInformationCircleOutline,
    ionEllipsisVertical,
    ionShareOutline,
    ionTrendingDownOutline,
    ionTrendingUpOutline,
    ionCloseOutline,
    ionAlertOutline,
    ionCreateOutline,
    ionTimeOutline,
    ionPersonCircleOutline,
    ionDocumentAttachOutline,
    ionImageOutline,
    ionChatboxOutline
  }
}
