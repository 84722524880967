<template>
  <div class="notification" v-click-outside="closePatients">
    <q-btn flat round color="primary" @click.prevent="openPatients" :icon="$icons.ionPersonCircleOutline" v-if="hasRight( 'customer' )">
        <q-chip v-if="patients.length" :label="patients.length" />
    </q-btn>
    <div class="notification-results box" v-show="isOpen">
      <div class="box-header"><h4>{{ $t('app.other.patientcollection' ) }}</h4></div>

      <ul class="box-content" v-if="patients && patients.length > 0">
        <li v-for="item in patients" class="list-item" v-bind:key="item.id" >
            <a>
              <div class="pull-right">
                <q-btn flat round color="negative" @click.prevent="removePatient( item )" :icon="$icons.ionCloseOutline"></q-btn>
              </div>
              <div class="flex-middle" @click.prevent="showPatient( item )">
                  <imgwrapper v-bind:classes="'img-wrapper-small'" v-bind:item="item"></imgwrapper>
                  <strong>{{ item.first_name }} {{ item.last_name }} <span v-if="item.birthdate">({{ item.birthdate | formatAge }})</span> </strong>
              </div>
            </a>
        </li>
      </ul>
      <ul v-else class="box-content">
        <li>{{ $t('app.other.nopatientcollection' ) }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { format, differenceInYears, toDate, parseISO } from "date-fns";
import { hasRight } from '@/services/hasRight';

export default {
  name: "PatientCollection",
  data() {
    return {
      isOpen: false,
      hasRight
    };
  },
  methods: {
    showPatient( item ) {
        this.$router.push({ path: `/customer/${item.id}` });
        this.closePatients();
    },
    removePatient( item ) {
        const _customers = [];
        for ( let i = 0; i < this.patients.length; i++ ) {
            if ( this.patients[ i ].id == item.id ) {
                continue;
            }
            _customers.push( this.patients[ i ] );
        }
        this.$store.commit( 'patients', _customers );
    },
    openPatients() {
      this.isOpen = true;
    },
    closePatients() {
      this.isOpen = false;
    },
  },
  computed: {
    patients() {
        return this.$store.state.patients;
    }
  },
  filters: {
    formatAge(val) {
      if (val) {
        return differenceInYears(new Date(), parseISO(val));
      }
    }
  },
  directives: {
    ClickOutside,
  },
};
</script>
