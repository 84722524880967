// import { hasRight } from '@/services/hasRight';

function hasRight( right, u, rightReturn ) {
    let user = JSON.parse(localStorage.getItem("user"));
    if ( u ) { user = u; }
    if ( !user ) {
        return false;
    }
    if ( user.type == 'api' ) { // no access to frontend
        return false;
    }

    if ( right == 'apitest' ) {
        return true;
    }

    let rights = {
        'appointments': { read: false, create: false, write: false, delete: false, own: false },
        'coupons': { read: false, create: false, write: false, delete: false, own: false },
        'customer': { read: false, create: false, write: false, delete: false, own: false },
        'documents': { read: false, create: false, write: false, delete: false, own: false },
        'goods': { read: false, create: false, write: false, delete: false, own: false },
        'invoices': { read: false, create: false, write: false, delete: false, own: false, opensonly: false },
        'locations': { read: false, create: false, write: false, delete: false, own: false },
        'media': { read: false, create: false, write: false, delete: false, own: false },
        'newsletter': { read: false, create: false, write: false, delete: false, own: false },
        'notes': { read: false, create: false, write: false, delete: false, own: false },
        'postal': { read: false, create: false, write: false, delete: false, own: false },
        'products': { read: false, create: false, write: false, delete: false, own: false },
        'proposals': { read: false, create: false, write: false, delete: false, own: false },
        'reports': { read: false, create: false, write: false, delete: false, own: false },
        'sales': { read: false, create: false, write: false, delete: false, own: false },
        'settings': { read: false, create: false, write: false, delete: false, own: false },
        'supplier': { read: false, create: false, write: false, delete: false, own: false },
        'tasks': { read: false, create: false, write: false, delete: false, own: false },
        'treatments': { read: false, create: false, write: false, delete: false, own: false },
        'users': { read: false, create: false, write: false, delete: false, own: false },
        'waitingroom': { read: false, create: false, write: false, delete: false, own: false },
        'workflow': { read: false, create: false, write: false, delete: false, own: false },
        'statistics': { read: false, create: false, write: false, delete: false, own: false },
        'calendar': { onlytoday: false, view: false, fullcalendar: false },
    }
    if ( rightReturn ) {
        return rights;
    }

    if ( user.type == 'external' ) {
        rights.appointments.create = true;
        rights.waitingroom = true;
        rights.calendar.onlytoday = true;
        rights.notes.create = true;
        rights.tasks.create = true;
        rights.postal.create = true;
        if ( right == 'isExternal' ) { return true; }
    }

    if ( user.type == 'secretary' ) {
        rights.appointments.create = true;
        rights.appointments.read = true;
        rights.waitingroom = true;
        rights.calendar.view = true;
        rights.invoices.create = true;
        rights.notes.create = true;
        rights.customer.read = true;
        rights.customer.create = true;
        rights.notes.read = true;
        rights.goods.write = true;
        rights.tasks.read = true;
        rights.tasks.write = true;
        rights.tasks.create = true;
        rights.tasks.own = true;
        rights.postal.create = true;
        rights.calendar.fullcalendar = true;
        if ( right == 'isSecretary' ) { return true; }
    }

    if ( user.type == 'medical' ) {
        rights.appointments.create = true;
        rights.appointments.read = true;
        rights.waitingroom = true;
        rights.calendar.view = true;
        rights.invoices.create = true;
        rights.notes.create = true;
        rights.customer.read = true;
        rights.customer.create = true;
        rights.notes.read = true;
        rights.goods.write = true;
        rights.tasks.read = true;
        rights.tasks.write = true;
        rights.tasks.create = true;
        rights.tasks.own = true;
        rights.treatments.read = true;
        rights.proposals.create = true;
        rights.reports.create = true;
        rights.media.create = true;
        rights.postal.create = true;
        rights.calendar.fullcalendar = true;
        if ( right == 'isMedical' ) { return true; }
    }

    if ( user.type == 'teamlead' ) {
        rights.appointments = true;
        rights.waitingroom = true;
        rights.calendar.view = true;
        rights.invoices.create = true;
        rights.invoices.read = true;
        rights.invoices.write = true;
        rights.invoices.opensonly = true;
        rights.notes.create = true;
        rights.customer.read = true;
        rights.customer.create = true;
        rights.notes.read = true;
        rights.goods = true;
        rights.tasks.read = true;
        rights.tasks.write = true;
        rights.tasks.create = true;
        rights.tasks.own = true;
        rights.newsletter = true;
        rights.postal.create = true;
        rights.postal.read = true;
        rights.postal.write = true;
        rights.calendar.fullcalendar = true;
        if ( right.indexOf( '/customer/:id' ) >= 0 ) { return true; }
        if ( right == 'isTeamlead' ) { return true; }
    }

    if ( user.type == 'doctor' ) {
        rights.appointments = true;
        rights.waitingroom = true;
        rights.invoices.create = true;
        rights.invoices.read = true;
        rights.invoices.write = true;
        rights.invoices.opensonly = true;
        rights.customer.read = true;
        rights.customer.create = true;
        rights.customer.write = true;
        rights.notes = true;
        rights.goods = true;
        rights.documents = true;
        rights.media = true;
        rights.reports = true;
        rights.treatments = true;
        rights.statistics = true;
        rights.tasks.read = true;
        rights.tasks.write = true;
        rights.tasks.create = true;
        rights.tasks.own = true;
        rights.newsletter = true;
        rights.postal.create = true;
        rights.calendar.fullcalendar = true;
        rights.calendar.read = true;
        rights.calendar.create = true;
        rights.calendar.write = true;
        if ( right == 'isDoctor' ) { return true; }
    }

    if ( user.type == 'admin' ) {
        if ( right.indexOf( '/own' ) >= 0 ) { return false; }
        if ( right == 'calendar/onlytoday' ) { return false; }
        if ( right == 'invoices/opensonly' ) { return false; }
        return true;
    }

    if ( typeof user.rights != 'undefined' ) {
        rights = JSON.parse( user.rights );
    }

    right = right.split( '/' );
    if ( typeof rights[ right[ 0 ] ] == 'undefined' ) { return false; }
    if ( rights[ right[ 0 ] ] === true ) { return true; }
    if ( right.length == 1 ) {
        for ( const i in rights[ right[ 0 ] ] ) {
            if ( rights[ right[ 0 ] ][ i ] === true ) {
                return true;
            }
        }
        return false;
    }

    if ( rights[ right[ 0 ] ][ right[ 1 ] ] === true ) { return true; }
    return false;

    if ( user.type == 'teamlead' ) {
        // teamlead - Zugriff auf alles Termine erstellen , Akte erstellen , Rechnungen einsehen , Mahnungen erstellen und löschen , neue Sekretärin eingeben , ( Personal Verwaltung )
        if ( right.indexOf( '/newsletter' ) >= 0 ) { return false; }
        if ( right.indexOf( '/products/' ) >= 0 ) { return false; }
        if ( right.indexOf( '/settings' ) >= 0 ) { return false; }
        if ( right.indexOf( '/customer/:id' ) >= 0 ) { return false; }
        if ( right.indexOf( '/statistics' ) >= 0 ) { return false; }
        if ( right.indexOf( 'role_' )  >= 0 && right.indexOf( 'role_secretary' ) < 0 ) { return false; }
        if ( right == 'stats' ) { return false; }
    }

    if ( user.type != 'teamlead' && right == 'isTeamlead' ) {
        return false;
    }

    if ( user.type != 'doctor' && right == 'isDoctor' ) {
        return false;
    }

    return true;
}

export {
    hasRight
}
