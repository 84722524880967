<template>
  <div v-if="adtype && isActive">
        <div v-if="adtype == 'login' && banner">
          <span class="text-meta text-right block">{{ $t( 'app.other.sponsoredby' ) }}</span>
          <a @click.prevent="openBanner"><img style="width:170px;height:70px;" :src="banner.image" /></a>
        </div>
        <div v-if="adtype == 'sidebar' && banner">
          <span class="text-meta text-right block">{{ $t( 'app.other.sponsoredby' ) }}</span>
          <a @click.prevent="openBanner"><img style="width:170px;height:240px;" :src="banner.image" /></a>
        </div>
        <div v-if="adtype == 'customerinfo' && banner">
          <span class="text-meta text-right block">{{ $t( 'app.other.sponsoredby' ) }}</span>
          <a @click.prevent="openBanner"><img style="width:468px;height:60px;" :src="banner.image" /></a><br />
        </div>
  </div>
</template>

<script>
const user = JSON.parse(localStorage.getItem("user"));
let companyId = null;
if ( user ) {
    companyId = user.company;
}

export default {
  name: "adBox",
  props: [ "adtype", "customer" ],
  data() {
    return {
        banner: false
    };
  },
  created() {
    this.getBanner();
  },
  computed: {
    isActive() {
        if ( !this.$store.state.module8 ) { return true; }
        return false;
    }
  },
  methods: {

    openBanner() {
        if ( !this.banner ) { return false; }
        if ( this.banner.clickable != 1 ) { return false; }
        window.open( `${process.env.VUE_APP_API_URL}/openbanner?id=${this.banner.id}` )
    },

    getBanner() {
        if ( this.isActive ) {
            let _url = `${process.env.VUE_APP_API_URL}/getbanner?type=${this.adtype}`;
            if ( this.customer ) {
                _url += '&customer=' + this.customer;
            }
            this.$http
              .get( _url )
              .then((response) => {
                this.banner = response.data;
              })
              .catch((err) => console.log(err));
        }
    }

  },
};
</script>
