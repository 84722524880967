<template>
  <div class="phone" v-click-outside="closePhone">
    <!-- <div class="icon notification-bell" @click.prevent="openNotification"></div> -->
    <q-btn flat round color="primary" @click.prevent="openPhone" :icon="$icons.ionCallOutline"></q-btn>
    <div class="notification-results box" v-show="isOpen">
      <div class="box-header" v-if="callWaiting" style="padding:0;">
          <h4 class="text-center">Eingehender Anruf</h4>
          <q-input outlined v-model="phonenumber" type="text" class="text-center" />
          <a style="display: block; margin-top: 10px; margin-bottom: 10px; width: 100%;" v-if="knownCustomer" @click.prevent="openCustomer( knownCustomer )">
            <customer v-bind:item="knownCustomer"></customer>
          </a>
          <br />
          <q-btn color="positive" style="float:none;" @click.prevent="startCall()" :label="$t('app.phone.answer')"></q-btn>
          <q-btn color="negative" style="margin-right: 0;" class="pull-right" @click.prevent="endCall()" :label="$t('app.phone.decline')"></q-btn>
      </div>
      <div class="box-header" v-else style="padding:0;">
          <h4 class="text-center">Anruf beginnen</h4>
          <q-input outlined v-model="phonenumber" type="text" class="text-center" />
          <a style="display: block; margin-top: 10px; margin-bottom: 10px; width: 100%;" v-if="knownCustomer" @click.prevent="openCustomer( knownCustomer )">
            <customer v-bind:item="knownCustomer"></customer>
          </a>
          <br />
          <q-btn color="positive" style="float:none;" @click.prevent="startCall()" :label="$t('app.phone.call')" :disabled="call"></q-btn>
          <q-btn color="negative" style="margin-right: 0;" class="pull-right" @click.prevent="endCall()" :label="$t('app.phone.hangup')" :disabled="!call"></q-btn>
      </div>
      <div class="box-content">
          <div class="text-muted text-center">{{state}}</div>
          <h4 class="text-center" v-if="startTimer">{{currentCallTime}}</h4>

      </div>

      <div v-if="log" class="box-content" style="padding:0;">
      <ul style="padding:0;">
        <li v-for="(item, index) in log" v-if="item.incoming && index >= page * limit && index < (page + 1) * limit" v-bind:key="item.call_id" style="list-style:none;" >
          <a>
              <div class="pull-left" style="font-size: 24px;">
                <q-icon name="close" color="negative" v-if="!item.successful"></q-icon>
                <q-icon name="done" color="positive" v-if="item.successful"></q-icon>
              </div>
              <div class="flex-middle" @click.prevent="callback( item )">
                  <span>{{item.start_time | formatDate}}<br />+{{item.remote_number}} ({{item.duration}}s)</span>
              </div>

          </a>
        </li>
      </ul>
      <q-btn color="primary" flat style="float:none;" @click.prevent="prevPage()" label="<" v-if="page > 0"></q-btn>
      <q-btn color="primary" flat style="margin-right: 0;" class="pull-right" @click.prevent="nextPage()" label=">" v-if="page < Math.floor( log.length / limit )"></q-btn>

      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { format, differenceInYears, toDate, parseISO } from "date-fns";
const usephoner = JSON.parse(localStorage.getItem("user"));

export default {
  name: "Phone",
  data() {
    return {
      page: 0,
      limit: 5,
      isOpen: false,
      voximplant: false,
      knownCustomer: false,
      startTimer: false,
      call: false,
      callWaiting: false,
      log: false,
      cid: false,
      state: 'initiate...',
      phonenumber: '',
      currentCallTime: '',
      stream: false,
      nexmo: false,
      locations: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    currentLocation() {
        return this.$store.state.location;
    },
  },
  methods: {
    prevPage() {
        this.page--;
        if ( this.page < 0 ) {
            this.page = 0;
        }
    },
    nextPage() {
        this.page++;
        if ( this.page > Math.floor( this.log.length / this.limit ) ) {
            this.page = Math.floor( this.log.length / this.limit );
        }
    },
    openCustomer( customer ) {
        if ( customer.blocked && customer.blocked != usephoner.id ) {
          return false;
        }
        window.open( `/customer/${customer.id}` );
    },
    init() {
        const _self = this;
        this.$http.get(`${window._serverAPI}/locations?key=${usephoner.api_token}`).then((response) => { this.locations = response.data; });
        window.phoneTrigger = function( e ) {
            window.setTimeout( function(){
                _self.trigger.call( _self, e );
            }, 10 );
        };
        if ( !window.VoxImplant ) { return window.setTimeout( function(){ _self.init.call( _self ); }, 500 ); }
        if ( this.voximplant ) { return false; }

        this.$http({
            url: `${window._serverAPI}/calllog?key=${usephoner.api_token}`,
        }).then((resp) => {
            const _logs = [];
            for ( let i = 0; i < resp.data.length; i++ ) {
                if ( !resp.data[ i ].incoming ) { continue; }
                _logs.push( resp.data[ i ] );
            }
            console.log( _logs );
            this.log = _logs;
        });

        this.voximplant = VoxImplant.getInstance();
        console.log( this.voximplant );
        this.voximplant.init().then( () => {
            console.log( 'initiated' );
            this.state = 'connect...';
            this.voximplant.connect().then( () => {
                this.state = 'login...';
                console.log( 'connected' );
                this.voximplant.login( 'tester@mca.draltintas.n6.voximplant.com', 'b,YrbJk2' ).then( () => {
                    this.state = 'ready...';
                    console.log( 'logged in' );
                    this.voximplant.on(VoxImplant.Events.IncomingCall, (e) => {
                        this.callWaiting = e.call;
                        this.state = 'incoming call...';
                        this.openPhone();
                        this.phonenumber = this.callWaiting.displayName();
                        const data = {
                            phone: this.callWaiting.displayName(),
                        };

                        this.$http({
                            url: `${window._serverAPI}/customers/whoiscalling?key=${usephoner.api_token}`,
                            data,
                            method: 'POST',
                        }).then((resp) => {
                            if ( resp.data && resp.data.id ) {
                                this.cid = resp.data.id;
                                this.knownCustomer = resp.data;
                                this.logWorkflow( 'inboundcall' );
                            }
                        });

                        console.log( e );
                        e.call.addEventListener(VoxImplant.CallEvents.Connected, () => {
                            this.state = 'call active...';
                            this.startTimer = Date.now();
                            this.getTimer();
                            if ( this.cid ) {
                                window.open( '/customer/' + this.cid + '/overview' );
                            }
                            console.log("Call connected!");
                        });
                        e.call.addEventListener(VoxImplant.CallEvents.Disconnected, () => {
                            this.state = 'call ended...';
                            this.startTimer = false;
                            this.cid = false;
                            this.knownCustomer = false;
                            console.log("Call disconnected!");
                            this.callWaiting = false;
                        });
                        e.call.addEventListener(VoxImplant.CallEvents.Failed, (e) => {
                            this.state = 'failure...';
                            this.startTimer = false;
                            this.knownCustomer = false;
                            this.cid = false;
                            console.log("Call failed!");
                            this.callWaiting = false;
                        });
                    });
                } )
            } )
        } );
    },
    getTimer() {
        if ( !this.startTimer ) {
            this.currentCallTime = '';
            return;
        }
        const _self = this;
        const _diff = Date.now() - this.startTimer;
        const _minutes = Math.floor( _diff / 60000 );
        let _seconds = Math.floor( ( _diff - _minutes * 60 ) / 1000 );
        if ( _seconds < 10 ) { _seconds = '0' + _seconds; }
        this.currentCallTime = _minutes + ':' + _seconds;
        window.setTimeout( function(){
            _self.getTimer.call( _self );
        }, 1000 );
    },
    trigger( e ) {
        if ( e.action == 'call' ) {
            this.phonenumber = e.phone;
            this.openPhone();
            if ( e.cid ) { this.cid = e.cid; }
        }
        console.log( e );
    },
    callback( item ) {
        this.phonenumber = '+' + item.remote_number;
    },
    startCall() {
        if ( !this.voximplant ) {
            return false;
        }
        if ( this.callWaiting ) {
            this.call = this.callWaiting;
            this.call.answer();
            this.getTimer();
            this.callWaiting = false;
        } else {
            if ( !this.phonenumber ) {
                return false;
            }
            let _fromPhoneNumber = '493031192602';
            for ( let i = 0; i < this.locations.length; i++ ) {
                if ( this.locations[ i ].id == this.currentLocation.id ) {
                    _fromPhoneNumber = this.locations[ i ].voximplant;
                    break;
                }
            }
            console.log( _fromPhoneNumber );

            this.$http({
                url: `${window._serverAPI}/customers/whoiscalling?key=${usephoner.api_token}`,
                data: { phone: this.phonenumber },
                method: 'POST',
            }).then((resp) => {
                if ( resp.data && resp.data.id ) {
                    this.knownCustomer = resp.data;
                }
            });

            const callSettings = {
                // number: '+442038083060',
                // number: '+442038083060',
                // number: '699120663',
                // number: '+491733465175',
                number: 'outbound' + this.phonenumber,
                debug: true,
                customData: _fromPhoneNumber,
                scenario: 'outbound',
                videoFlags: {
                    sendVideo: false,
                    receiveVideo: false
                }
            };
            this.state = 'dial...';
            console.log( this.cid );
            this.logWorkflow( 'outboundcall' );

            this.call = this.voximplant.call( callSettings );
            this.call.addEventListener(VoxImplant.CallEvents.Connected, () => {
                console.log("Call connected!");
                this.startTimer = Date.now();
                this.getTimer();
                this.state = 'call active...';
            });
            this.call.addEventListener(VoxImplant.CallEvents.Disconnected, () => {
                console.log("Call disconnected!");
                this.knownCustomer = false;
                this.startTimer = false;
                this.cid = false;
                this.state = 'call ended...';
            });
            this.call.addEventListener(VoxImplant.CallEvents.Failed, (e) => {
                console.log("Call failed!", e);
                this.knownCustomer = false;
                this.startTimer = false;
                this.cid = false;
                this.state = 'failure...';
            });
        }

    },
    endCall() {
        if ( this.callWaiting ) {
            this.callWaiting.decline();
            this.callWaiting = false;
        }
        if ( this.call ) {
            this.call.hangup();
        }
        this.phonenumber = '';
        this.knownCustomer = false;
        this.callWaiting = false;
        this.call = false;
    },
    openPhone() {
      this.isOpen = true;
    },
    closePhone() {
      this.isOpen = false;
    },
    logWorkflow( type ) {
        if ( this.cid ) {
            const data = {
                type: type,
                table: 'calls',
                text: this.phonenumber,
                date: format( new Date(), "yyyy-MM-dd HH:mm:ss"),
                user: usephoner.id,
                company: usephoner.company,
                customer: this.cid,
                source: 'manually',
                pid: 0
            };

            this.$http({
                url: `${window._serverAPI}/workflow?key=${usephoner.api_token}`,
                data,
                method: 'POST',
            }).then((resp) => {});
        }
    }
  },
  filters: {
    formatAge(val) {
      if (val) {
        return differenceInYears(new Date(), parseISO(val));
      }
    },
    formatDate(val) {
      return format(parseISO(val), "dd.MM.yyyy HH:mm");
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
