<template>
  <div class="customer-item flex flex-middle" v-if="item">
    <span v-if="item.blocked" :class="{ 'task-dot': true, 'task-state-danger': ( item.blocked && item.blocked != 'null' ? true : false ) }"></span>
    <imgwrapper v-bind:classes="'img-wrapper-small'" v-bind:item="item" :forcespath="forcespath" ></imgwrapper>
    <div>
      <strong>
        {{ item.first_name }} {{ item.last_name }}
        <q-icon v-if="item.verifiedmail" name="verified" class="text-primary" :title="$t( 'app.customer.emailverified' )" />
        <q-icon v-if="item.verifiedphone" name="verified" class="text-positive" :title="$t( 'app.customer.phoneverified' )" />
        <q-icon v-if="item.verifieddata" name="verified_user" class="text-warning" :title="$t( 'app.customer.dataverified' )" />
        <q-icon v-if="item.verified_alt" name="fact_check" class="text-secondary" :title="$t( 'app.customer.doctorverified' )" />
        <q-icon v-if="item.bonus > 0" name="star_rate" class="text-accent" :title="$t('app.customer.bonus')" /><span v-if="item.bonus > 0" class="bonusmeta">{{item.bonus}}</span>
        <q-icon v-if="item.refcount > 0" name="groups" class="text-info" :title="$t('app.customer.refcount')" /><span v-if="item.refcount > 0" class="bonusmeta">{{item.refcount}}</span>
      </strong>
      <span>
        <div v-if="item.gender" class="inline-block">
          {{ $t(item.gender) }}
        </div>
        <div style="margin-right: 4px" v-if="item.gender && (item.birthdate || item.city)" class="inline-block" >
          ,
        </div>
        <div v-if="item && item.birthdate && item.birthdate.length > 10" class="inline-block">
          {{ item.birthdate | formatAge }} {{ $t( 'app.other.years' ) }}
        </div>
        <div style="margin-right: 4px" v-if="item && item.birthdate && item.birthdate.length > 10 && item.city" class="inline-block" >
          ,
        </div>
        <div v-if="item.city" class="inline-block">
          {{ item.city }}
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { format, parseISO, differenceInYears } from 'date-fns';

export default {
  name: 'customer',
  props: ['item','forcespath'],
  data() {
    return {};
  },
  filters: {
    formatAge(val) {
      if (val) {
        const age = differenceInYears(new Date(), parseISO(val));
        return Number.isInteger(age) ? age : null;
      }
    },
  },
};
</script>
