<template>
  <transition name="slidein-right">
    <div class="modal-backdrop">
      <div
        :class="'sidemodal ' + className"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header"></slot>
          <q-btn
            flat
            @click.prevent="close"
            aria-label="Close modal"
            round
            style="position: absolute; right: 20px; top: 10px"
            color="blue-grey-9"
            icon="close"
          />
        </header>
        <section class="modal-body" id="modalDescription">
          <slot name="body"></slot>
        </section>
        <footer class="modal-footer">
          <slot name="footer"></slot>
        </footer>

        <portal-target name="sidemodal-footer"> </portal-target>
      </div>
      <div class="modal-blur" @click.prevent="close"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "sidemodal",
  created() {
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    };

    document.addEventListener("keydown", escapeHandler);
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  props: ["className"],
};
</script>
