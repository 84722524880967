<template>
  <div class="notification" v-click-outside="closeNotification">
    <!-- <div class="icon notification-bell" @click.prevent="openNotification"></div> -->
    <q-btn flat round color="primary" @click.prevent="openNotification" :icon="$icons.ionNotificationsOutline">
        <q-chip v-if="notifications.length" :label="notifications.length" />
    </q-btn>
    <div class="notification-results box" v-show="isOpen">
      <div class="box-header"><h4>{{ $t('app.other.notifications' ) }} <q-btn flat round color="negative" class="pull-right" @click.prevent="removeNotification( false )" :icon="$icons.ionCloseOutline" :label="$t('app.user.deletenotifications')"></q-btn></h4></div>

      <ul class="box-content" v-if="notifications && notifications.length > 0">
        <li v-for="item in notifications" v-if="!item.deleted" :class="[ 'list-item', 'meta-'+item.priority ]" v-bind:key="item.id" >
          <a>
              <div class="pull-right" v-if="item.id">
                <q-btn flat round color="negative" class="pull-right" @click.prevent="removeNotification( item )" :icon="$icons.ionCloseOutline"></q-btn>
              </div>
              <div class="flex-middle" @click.prevent="showNotification( item )">
                  <span>{{item.created_at | formatDate}}<br />{{item.text}}</span>
              </div>

          </a>
        </li>
      </ul>
      <ul v-else class="box-content">
        <li>{{ $t('app.other.nonotifications' ) }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { format, differenceInYears, toDate, parseISO } from "date-fns";
const user = JSON.parse(localStorage.getItem("user"));

export default {
  name: "Notifictaion",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    removeNotification( item ) {
        if ( item === false ) {
            this.$http.get( `${window._serverAPI}/notification/delete?key=${user.api_token}` ).then((response) => {});
            for ( let i = 0; i < this.notifications.length; i++ ) {
                this.notifications[ i ].deleted = true;
            }
        } else {
            this.$http.delete( `${window._serverAPI}/notification/${item.id}?key=${user.api_token}` ).then((response) => {});
            item.deleted = true;
        }
    },
    showNotification( item ) {
        if ( item.related_target == 'tasks' ) {
            this.$router.push({ path: `/tasks` });
            this.closeNotification();
        }
        if ( item.related_target == 'goods' ) {
            this.$router.push({ path: `/goods` });
            this.closeNotification();
        }
    },
    openNotification() {
      this.isOpen = true;
    },
    closeNotification() {
      this.isOpen = false;
    },
  },
  computed: {
    notifications() {
        return this.$store.state.notifications;
    }
  },
  filters: {
    formatAge(val) {
      if (val) {
        return differenceInYears(new Date(), parseISO(val));
      }
    },
    formatDate(val) {
      return format(parseISO(val), "dd.MM.yyyy HH:mm");
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
