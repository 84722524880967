import Vue from 'vue';

// import 'quasar/dist/quasar.ie.polyfills';
import '@quasar/extras/material-icons/material-icons.css';
// import '@quasar/extras/eva-icons/eva-icons.css'

import Quasar from 'quasar/src/vue-plugin.js';import QDate from 'quasar/src/components/date/QDate.js';import QRadio from 'quasar/src/components/radio/QRadio.js';import QInput from 'quasar/src/components/input/QInput.js';import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import QSelect from 'quasar/src/components/select/QSelect.js';import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';import QBtn from 'quasar/src/components/btn/QBtn.js';import Notify from 'quasar/src/plugins/Notify.js';import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';import QMenu from 'quasar/src/components/menu/QMenu.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';import QEditor from 'quasar/src/components/editor/QEditor.js';import QBadge from 'quasar/src/components/badge/QBadge.js';

Vue.use(Quasar, {
  components: {
    QRadio,
    QInput,
    QDate,
    QCheckbox,
    QSelect,
    QTabPanels,
    QTabPanel,
    QRouteTab,
    QBtn,
    QTooltip,
    QMenu,
    QEditor,
    QBadge
  },
  plugins: {
    Notify
  },
  config: {
    notify: {
      /* look at QUASARCONFOPTIONS from the API card (bottom of page) */
    }
  },
  directives: {
    ClosePopup
  }
});
