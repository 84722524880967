<template>
  <div id="app" ref="app">
    <script src="/static/recognition.js" v-if="isBeta" type="application/javascript"></script>
    <script src="/static/ably.min-1.js" v-if="isBeta" type="application/javascript"></script>
    <div id="header"></div>
    <div v-if="isLoggedIn && !isDataCheck && !isSales && !isSeo && !isMarketing" class="sidebar-dark" id="sidebar" v-bind:class="{ open: navOpen === true, smallview: smallview === true }" >
      <script src="/static/voximplant.min.js" v-if="isBeta" type="application/javascript"></script>
      <div class="logo">
        <a href="/">
          <img class="t-hide m-show" alt="Aesthetic Cloud" src="./assets/logo_svg.svg" />
          <img class="d-hide t-show m-hide" alt="Aesthetic Cloud" src="./assets/favicon.png" />
        </a>
      </div>
      <Userinfo class="d-hide m-show"></Userinfo>
      <Navigation></Navigation>
      <nav class="togglesmallview">
        <ul>
          <li>
            <a @click.prevent="smallview = !smallview">
                <q-icon name="menu_open" />
                {{ $t( 'app.other.openclose' ) }}
            </a>
          </li>
        </ul>
      </nav>
      <div class="adbox_menu">
        <adBox adtype="sidebar" />
      </div>
    </div>

    <div v-if="isLoggedIn && !isDataCheck" id="main">
      <div class="top-bar">
          <div class="row">
              <div class="col-xs-12 col-sm-2 p-top-15" v-if="!isSales && !isMarketing && !isSeo">
                  <portal-target name="topbar-left"> </portal-target>
              </div>
              <div class="col-xs-12 col-sm-5" v-if="!isSales && !isMarketing && !isSeo">
                  <Search></Search>
              </div>
              <div class="col-xs-12 col-sm-5 p-top-10">
                  <div class="float-right nav-icon d-hide m-show" @click.prevent="openMobileNav" v-bind:class="{ open: navOpen === true }">
                    <span></span>
                    <span></span>
                  </div>
                  <Userinfo class="float-right m-hide"></Userinfo>
                  <div class="help float-right" v-if="!isSales && !isMarketing && !isSeo && isBeta">
                    <q-btn-dropdown flat round color="primary" icon="contact_support">
                      <q-list>
                        <q-item clickable v-close-popup @click="openHelp">
                          <q-item-section>
                            <q-item-label>{{ $t( 'app.help.tutorials' ) }}</q-item-label>
                          </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup @click="openReport">
                          <q-item-section>
                            <q-item-label>{{ $t( 'app.help.sendfailure' ) }}</q-item-label>
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-btn-dropdown>
                  </div>
                  <Notification class="float-right" v-if="!isSales && !isMarketing && !isSeo"></Notification>
                  <PatientCollection class="float-right" v-if="!isSales && !isMarketing && !isSeo"></PatientCollection>
                  <Phone class="float-right m-hide" v-if="!isSales && !isMarketing && !isSeo && isBeta"></Phone>
                  <br class="clear" />
              </div>
          </div>
      </div>



      <router-view />
    </div>
    <div v-else id="main" :class="( isSales || isMarketing || isSeo ) && isLoggedIn ? '' : 'nosidebar'">
      <router-view />
    </div>

    <portal-target name="modal"> </portal-target>
    <portal-target name="modal2"> </portal-target>
    <portal-target name="modal3"> </portal-target>
    <portal-target name="modal4"> </portal-target>

    <modal v-if="helpOpen" @close="closeModal" class="big">
      <h3 class="title" slot="header">{{ $t( 'app.help.tutorials' ) }}</h3>
      <div slot="body">
        <div class="clear text-block">

            <div class="form-field col-12">
              <q-input class="clear" outlined v-model="searchhelp" :label="$t( 'app.help.searchtutorial' )" stack-label />
            </div>

            <div class="form-field col-6" v-for="item in videos" v-bind:key="item.name">
                <p><b>{{ item.name }}</b></p>
                <div v-html="item.code"></div>
            </div>

        </div>
      </div>
    </modal>

    <modal v-if="reportOpen" @close="closeModal" class="big">
      <h3 class="title" slot="header">{{ $t( 'app.help.sendfailure' ) }}</h3>
      <div slot="body">
        <div class="clear text-block">
            <div class="form-field col-12">
              <q-input type="textarea" class="clear" outlined v-model="report.description" :label="$t( 'app.help.reportdescription' )" stack-label />
            </div>
            <div class="form-field col-12">
              <q-input type="textarea" class="clear" outlined v-model="report.recreate" :label="$t( 'app.help.reportrecreate' )" stack-label />
            </div>
            <div class="form-field col-12">
              <q-input type="textarea" class="clear" outlined v-model="report.presetup" :label="$t( 'app.help.reportpresetup' )" stack-label />
            </div>

            <div class="form-field col-12" v-if="screenshot">
                <img :src="screenshot" style="max-width:100%;" />
            </div>

            <q-btn @click.prevent="sendReport" color="primary" :label="$t( 'app.help.sendfailure' )" size="md" />
        </div>
      </div>
    </modal>

  </div>
</template>

<style lang="scss">
@import "../src/styles/global.scss";
</style>

<script>
import Navigation from "./components/global/Navigation";
import Search from "./components/global/Search";
import PatientCollection from "./components/global/Patientcollection";
import Notification from "./components/global/Notification";
import Userinfo from "./components/global/Userinfo";
import Phone from "./components/global/Phone";
import adBox from "@/components/adbox";
import store from "./store.js";
import axios from "axios";
import { format, parse, toDate, parseISO } from "date-fns";
const BASE_URL = window._serverAPI;

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      navOpen: false,
      notificationInterval: false,
      smallview: false,
      helpOpen: false,
      reportOpen: false,
      screenshot: false,
      report: { description: '', recreate: '', presetup: '' },
      videos: [
        { name: 'How the CEO of Loom uses Loom', code: '<div style="position: relative; padding-bottom: 61.224489795918366%; height: 0;"><iframe src="https://www.loom.com/embed/5bbdeb480ba84e65b1b3de8c190e2003" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>' },
        { name: 'How the CEO of Loom uses Loom', code: '<div style="position: relative; padding-bottom: 61.224489795918366%; height: 0;"><iframe src="https://www.loom.com/embed/5bbdeb480ba84e65b1b3de8c190e2003" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>' },
      ],
      searchhelp: ''
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isBeta() {
        return this.$store.state.beta;
    },
    isDataCheck() {
      return this.$store.getters.isDataCheck;
    },
    isSales() {
      return this.$store.getters.isSales;
    },
    isSeo() {
      return this.$store.getters.isSeo;
    },
    isMarketing() {
      return this.$store.getters.isMarketing;
    },
  },
  created: function(){
    this.initHeartbeat();
    // this.$i18n.locale = this.item.language;
    const user = JSON.parse(localStorage.getItem("user"));
    if ( user ) {
        if ( user.type != 'sales' ) {
            this.$http
              .get(
                `${window._serverAPI}/settings?type=softwaresettings&key=${user.api_token}`
              )
              .then((response) => {
                if ( response.data.length ) {
                    const item = JSON.parse( response.data[ 0 ].value );
                    this.$i18n.locale = item.language;
                    this.$store.commit( 'setBeta', item.isbeta );
                }
              });

            this.$http
              .get(
                `${window._serverAPI}/settings?type=modules&key=${user.api_token}`
              )
              .then((response) => {
                if ( response.data.length ) {
                    const item = JSON.parse( response.data[ 0 ].value );
                    this.$store.commit( 'initStore', item );
                }
              });
          }
    }

  },
  methods: {
    openHelp() {
        this.helpOpen = true;
    },
    async openReport() {
        const options = {
            logging: true,
            useCORS: true,
            imageTimeout: 0,
            foreignObjectRendering: true,
            type: 'dataURL'
        };
        this.screenshot = await this.$html2canvas( this.$refs.app, options );
        this.report = { description: '', recreate: '', presetup: '' };
        this.reportOpen = true;
    },
    sendReport() {
        const user = JSON.parse(localStorage.getItem("user"));
        const url = `${BASE_URL}/support/failure?key=${user.api_token}`;
        const formData = new FormData();

        formData.append( `file`, this.screenshot );
        formData.append( `description`, this.report.description );
        formData.append( `recreate`, this.report.recreate );
        formData.append( `presetup`, this.report.presetup );
        formData.append( `company`, user.company );
        axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then( ( x ) => {
            this.reportOpen = false;
            this.$q.notify({ type: "positive", position: "top", message: this.$t( 'app.help.reportsended' ), timeout: 2000, });
        } );
    },
    closeModal() {
        this.helpOpen = false;
        this.reportOpen = false;
    },
    doHeartbeat() {
        if ( !this.isLoggedIn ) { return true; }
        if ( this.isSales || this.isMarketing || this.isSeo ) { return true; }
        const user = JSON.parse(localStorage.getItem("user"));
        axios.get( `${window._serverAPI}/notification/${user.id}?key=${user.api_token}` ).then((response) => {
            const notifications = [];

            for ( const n in response.data ) {
                if ( response.data[ n ].related_target == 'realopencustomer' && !this.isDataCheck ) {
                    this.$http.delete( `${window._serverAPI}/notification/${response.data[ n ].id}?key=${user.api_token}` ).then((resp) => {
                        window.location.href = `/customer/${response.data[ n ].related_id}`;
                    });
                    continue;
                }

                if ( response.data[ n ].related_target == 'opencustomer' ) {
                    if ( this.isDataCheck ) {
                        this.$http.delete( `${window._serverAPI}/notification/${response.data[ n ].id}?key=${user.api_token}` ).then((response) => {});
                        this.$router.push({
                          path: `/datacheck/${response.data[ n ].related_id}/1`,
                        });
                        continue;
                    }
                }

                if ( response.data[ n ].related_target == 'opendocument' ) {
                    if ( this.isDataCheck ) {
                        this.$http.delete( `${window._serverAPI}/notification/${response.data[ n ].id}?key=${user.api_token}` ).then((response) => {});
                        this.$router.push({
                          path: `/datacheck/document/${response.data[ n ].related_id}`,
                        });
                        continue;
                    }
                }

                if ( response.data[ n ].related_target == 'rateoperation' ) {
                    if ( this.isDataCheck ) {
                        this.$http.delete( `${window._serverAPI}/notification/${response.data[ n ].id}?key=${user.api_token}` ).then((response) => {});
                        this.$router.push({
                          path: `/datacheck/rateoperation/${response.data[ n ].related_id}`,
                        });
                        continue;
                    }
                }

                if ( response.data[ n ].related_target == 'beforecontrol' ) {
                    if ( this.isDataCheck ) {
                        this.$http.delete( `${window._serverAPI}/notification/${response.data[ n ].id}?key=${user.api_token}` ).then((response) => {});
                        this.$router.push({
                          path: `/datacheck/beforecontrol/${response.data[ n ].related_id}`,
                        });
                        continue;
                    }
                }

                if ( !this.isDataCheck ) {
                    if ( !response.data[ n ].priority ) {
                        response.data[ n ].priority = 'info';
                    }
                    notifications.push( response.data[ n ] );
                }
            }
            if ( this.isDataCheck ) {
                return true;
            }

            axios
              .get(
                `${window._serverAPI}/tasks?key=${user.api_token}`
              )
              .then((response) => {
                let _openTasks = 0;
                for ( const i in response.data.data ) {
                    if ( response.data.data[ i ].assigned_user == user.id ) {
                        _openTasks++;
                        // continue;
                    }
                    if ( response.data.data[ i ].created_user == user.id && response.data.data[ i ].retime && response.data.data[ i ].retime != 'null' ) {
                        notifications.push({
                            user: user.id,
                            related_target: 'tasks',
                            related_id: response.data.data[ i ].id,
                            text: this.$t( 'app.tasks.notification.retimetask' ),
                            company: response.data.data[ i ].company,
                            created_at: response.data.data[ i ].created_at,
                            priority: 'warning'
                        });
                        // continue;
                    }
                    if ( response.data.data[ i ].created_user == user.id && parseISO( response.data.data[ i ].date_due ).getTime() < new Date().getTime() ) {
                        notifications.push({
                            user: user.id,
                            related_target: 'tasks',
                            related_id: response.data.data[ i ].id,
                            text: this.$t( 'app.tasks.notification.overduetask' ),
                            company: response.data.data[ i ].company,
                            created_at: response.data.data[ i ].created_at,
                            priority: 'negative'
                        });
                        // continue;
                    }
                    if ( response.data.data[ i ].created_user == user.id && response.data.data[ i ].question ) {
                        notifications.push({
                            user: user.id,
                            related_target: 'tasks',
                            related_id: response.data.data[ i ].id,
                            text: this.$t( 'app.tasks.notification.taskquestion' ),
                            company: response.data.data[ i ].company,
                            created_at: response.data.data[ i ].created_at,
                            priority: 'negative'
                        });
                        // continue;
                    }
                }

                if ( _openTasks ) {
                    notifications.push({
                        user: user.id,
                        related_target: 'tasks',
                        related_id: 0,
                        text: this.$t( 'app.tasks.notification.openquestion' + ( _openTasks > 1 ? 's' : '' ), { count: _openTasks } ),
                        company: 0,
                        created_at: format( new Date(), 'yyyy-MM-dd HH:mm:ss' ),
                        priority: 'info'
                    });
                }

                this.$store.commit('notify', notifications);
              })
              .catch((err) => console.log(err));

        } );

    },
    initHeartbeat() {
        const _self = this;
        this.notificationInterval = setInterval( function(){
            _self.doHeartbeat.call( _self );
        }, ( this.isDataCheck ? 10000 : 60000 ) );
        this.doHeartbeat();
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.go("/login");
      });
    },
    openMobileNav() {
      this.navOpen = this.navOpen != true;
    },
  },
  watch: {
    $route(to, from) {
      this.navOpen = false;
    },
  },
  beforeDestroy () {
    if ( this.notificationInterval ) {
      clearInterval( this.notificationInterval );
    }
  },
  components: {
    Navigation,
    Search,
    Notification,
    Userinfo,
    adBox,
    PatientCollection,
    Phone
  },
};
</script>
