<template>
  <div class="toolbar">
    <div class="row-grid clear">
      <div class="toolbar-left">
        <slot name="left"></slot>
      </div>
      <div class="toolbar-right">
        <portal-target name="toolbar-right"> </portal-target>
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toolbar",
  data() {
    return {};
  },
};
</script>
