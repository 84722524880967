import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueSignaturePad from 'vue-signature-pad';
import wysiwyg from 'vue-wysiwyg';
import PortalVue from 'portal-vue';
import VueHtml2Canvas from 'vue-html2canvas';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './i18n';

import icons from './icons'



import toolbar from './components/global/Toolbar.vue';

import imgwrapper from './components/imgwrapper';
import sidemodal from './components/sidemodal';
import modal from './components/modal';
import customer from './components/items/customer';
import user from './components/items/user';
import product from './components/items/product';

import VueQuagga from 'vue-quaggajs';

import './quasar';

Vue.use(Vuex);
Vue.use(PortalVue);
Vue.use(VueSignaturePad);
Vue.use( VueQuagga );
Vue.use(VueHtml2Canvas);
Vue.use(wysiwyg, {
  maxHeight: '500px',
});

Vue.component('toolbar', toolbar);
Vue.component('sidemodal', sidemodal);
Vue.component('modal', modal);
Vue.component('imgwrapper', imgwrapper);
Vue.component('customer', customer);
Vue.component('user', user);
Vue.component('product', product);

// import icons
Vue.prototype.$icons = icons();

// set auth header
axios.defaults.headers.common.Authorization = `Bearer ${store.state.token}`;
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response && error.response.data) {
        if ( error.response.data.indexOf ) {
            if ( error.response.data.indexOf( 'Unauthorized, check your credentials.' ) >= 0 ) {
                store.dispatch("logout").then(() => {
                  router.go("/login");
                });
            }
        }
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
});

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

const token = localStorage.getItem('token');
if (token) {
  Vue.prototype.$http.defaults.headers.common.Authorization = token;
}

window._serverAPI = process.env.VUE_APP_API_URL;
if ( process.env.VUE_APP_MODE == 'client' ) {
  axios.get( `${process.env.VUE_APP_API_URL}/datamode?company=${process.env.VUE_APP_COMPANY}` ).then(( response ) => {
    if ( response.data == 2 ) {
        window._serverAPI = process.env.VUE_APP_API_URL_INT;
    }
    new Vue({ router, store, i18n, axios, render: (h) => h(App) }).$mount( '#app' );
  }).catch( ( err ) => console.log( err ) );
} else {
  new Vue({ router, store, i18n, axios, render: (h) => h(App) }).$mount( '#app' );
}
