import axios from "axios";

const privateAuth = {

    isLoggedIn( positive, negative, path ) {

        const params = new URLSearchParams( window.location.search );
        if ( params.get( 'toc' ) != sessionStorage.getItem( 'toc' ) ) {
            sessionStorage.setItem( 'path', JSON.stringify( false ) );
            const _data = { toc: params.get( 'toc' ), customer: params.get( 'customer' ), company: params.get( 'company' ) };
            axios.post( `${window._serverAPI}/private/login`, _data ).then((response) => {
                if ( response.data ) {
                    sessionStorage.setItem( 'toc', response.data );
                    sessionStorage.setItem( 'customer', params.get( 'customer' ) );
                    sessionStorage.setItem( 'company', params.get( 'company' ) );
                    if ( positive ) {
                        positive();
                    }
                } else {
                    if ( path ) {
                        path.customer = params.get( 'customer' );
                        path.company = params.get( 'company' );
                        sessionStorage.setItem( 'path', JSON.stringify( path ) );
                    }
                    if ( negative ) {
                        negative();
                    }
                }
            });
        } else if ( sessionStorage.getItem( 'toc' ) ) {
            if ( positive ) {
                positive();
            }
        }
    }

};

export {
    privateAuth
}
