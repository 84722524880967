<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @keydown.esc="close()" tabindex="0">
      <div class="modal box box-big" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" >
        <header class="box-header modal-header" id="modalTitle">
          <slot name="header"></slot>
          <q-btn flat @click.prevent="close" aria-label="Close modal" round style="position: absolute; right: 20px; top: 10px" color="blue-grey-9" icon="close" />
        </header>
        <section class="modal-body box-content" id="modalDescription">
          <slot name="body"></slot>

          <div class="nobox">
            <slot name="nobox"></slot>
          </div>
        </section>
        <footer class="modal-footer">
          <slot name="footer"></slot>
        </footer>

        <portal-target name="modal-footer"> </portal-target>
      </div>
      <div class="modal-blur" @click.prevent="close"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal",
  created() {
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    };

    document.addEventListener("keydown", escapeHandler);
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
